// routes
import { PATH_AUTH, PATH_DOCS, PATH_PAGE, PATH_DASHBOARD } from '../../routes/paths';
// components
// import { PATH_AFTER_LOGIN } from '../../config';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
    width: 22,
    height: 22,
};

const menuConfig = [
    // {
    //     title: 'Home',
    //     icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    //     path: '/',
    // },
    {
        title: 'About',
        path: PATH_PAGE.about,
        icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
        children: [
            {
                items:[
                    { title: 'Why\u00A0Us', path: PATH_PAGE.whyus },
                    { title: 'Meet\u00A0Our\u00A0Team', path: PATH_PAGE.team },
                    { title: 'Blog', path: PATH_PAGE.blog },
                ]
            }
        ]
    },
    {
        title: 'Service',
        path: PATH_PAGE.service,
        icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
        children: [
            {
                items:[
                    { title: 'Remote\u00A0Patient\u00A0Monitoring', path: PATH_PAGE.rpm },
                    { title: 'Chronic\u00A0Care\u00A0Management', path: PATH_PAGE.ccm },
                ]
            }
        ]
    },
    {
        title: 'Patients',
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: PATH_PAGE.patients,
        children: [
            {
                items:[
                    { title: 'Patients', path: PATH_PAGE.patients },
                    { title: 'patient\u00A0App', path: PATH_PAGE.patinetApp },
                ]
            }
        ]
    },
    {
        title: 'Provider',
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: PATH_PAGE.doctors,
    },
    // {
    //     title: 'Resources',
    //     path: PATH_PAGE.resources,
    //     icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
    //     children: [
    //         {
    //             items: [
    //                 { title: 'Doctors', path: PATH_PAGE.doctors },
    //                 { title: 'Patients', path: PATH_PAGE.patients },
    //                 { title: 'Blog', path: PATH_PAGE.blog },
    //             ],
    //           },
    //     ],
    // },
    // {
    //     title: 'How it works',
    //     path: '/',
    //     icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
    // },
    
    {
        title: 'FAQs',
        path: PATH_PAGE.faqs,
        icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
        children: [
            {
                items:[
                    { title: 'RPM', path: PATH_PAGE.rpmfaq },
                    { title: 'CCM', path: PATH_PAGE.ccmfaq },
                ]
            }
        ]
    },

    // {
    //     title: 'FAQs',
    //     path: PATH_PAGE.faqs,
    //     icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
    // },
];
    
export default menuConfig;
